@import "scss/functions";
@import "scss/variables";
@import "scss/mixins";
@import "scss/root";
@import "scss/reboot";
//@import "type";
//@import "images";
//@import "code";
//@import "scss/grid";
//@import "tables";
@import "scss/forms";
@import "scss/buttons";
//@import "transitions";
//@import "dropdown";
//@import "button-group";
//@import "input-group";
//@import "custom-forms";
//@import "nav";
//@import "navbar";
//@import "card";
//@import "breadcrumb";
//@import "pagination";
//@import "badge";
//@import "jumbotron";
@import "scss/alert";
//@import "progress";
//@import "media";
//@import "list-group";
//@import "close";
//@import "toasts";
//@import "modal";
//@import "tooltip";
//@import "popover";
//@import "carousel";
//@import "spinners";
//@import "utilities";
//@import "print";

@import "fa-5.12.1/scss/fontawesome";
@import "fa-5.12.1/scss/brands";
@import "fa-5.12.1/scss/solid";

$break-min: 960px;
$break-max: 959px;

@import url(https://fonts.googleapis.com/css?family=Gafata|Montez);

body {
    font-family: 'Gafata', serif;
    background-color: #ffffff;
    letter-spacing: .01em;
    line-height: 135%;
    color: #444;
}

#header {
    background: #000;
    border-bottom: 10px solid #bfbfbf;
}

#parallax-p {
    max-height: 75vh !important;
}

.parallax-p {
    position: relative;
    overflow: hidden;
}

#parallax {
    width: 100%;
}

#title {
    position: absolute;
    z-index: 50;
    color: white;
    text-shadow: 0 0 5px #000;
    width: 100%;
    text-align: center;
    transition: opacity .33s;
    height: auto;
    bottom: 0;

    .valign {
        position: relative;
        top: auto;
        bottom: 10px;
        transform: none;
    }

    @media (min-width: 450px) {
        height: 100%;
        bottom: auto;

        .valign {
            top: 50%;
            bottom: auto;
            -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */
                -ms-transform: translateY(-50%); /* IE 9 */
                    transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
        }
    }

    h1 {
        font-family: 'Montez', sans-serif;
        font-size: 5.25em;
        @media (max-width: 750px) {
            font-size: 3.25em;
        }
        @media (max-width: 450px) {
            font-size: 2.5em;
        }
        line-height: .6em;
        font-weight: bold;

        span {
            display:inline-block;
            font-family: 'Gafata', serif;
            font-size: 36px;
            font-weight: normal;

            // -> h2
            @media (max-width: 750px) {
                font-size: 0.50em;
            }
            @media (max-width: 450px) {
                font-size: .42em;
            }
        }
    }

    // <- h1 span
    h2 {
        /*
        font-size: 1.25em;
        @media (max-width: 750px) {
            font-size: 0.50em;
        }
        @media (max-width: 450px) {
            font-size: 1em;
        }
        */
        font-weight: normal;

        font-size: 1.15em;
        position: relative;
        top: 15px;
    }

    &.top {
        position: fixed;
        left: 0;
        bottom: auto;
        display: inline-block;
        width: auto;
        padding: 15px 0 0 15px;
        z-index: 100;
        opacity: 1 !important;
        height: auto !important;

        .valign {
            position: relative;
            top: 0;
            bottom: auto;
            transform: none;
        }

        h1 {
            font-size: 1.95em;
            margin: 0;
            line-height: .9em;

            span {
                font-size: .5em;
            }
        }

        h2 {
            font-size: 1em;
            margin: 0;
            display:none;
        }
    }
}

h2.main {
    border-bottom: 1px solid #cecece;
    padding: 25px;
    margin: 10px 0 0 0;
    color: #756b59;
    text-transform: uppercase;
}

nav#main {
    position: fixed;
    width: 100%;
    z-index: 100;

    ul#secondary {
        position: absolute;
        z-index: 100;
        list-style-type: none;
        margin: 0 15px 0 0;
        padding: 0;
        left: 15px !important;

        li {
            float: left;
            padding: 8px 4px 0 4px;

            a, a:hover {
                color: white;
                text-shadow: 0 0 5px #000;
            }
        }
    }

    &.sticky ul#secondary {
        position: static;
    }

    .menu-is-active & {
        z-index: 500;
    }
    
    & > ul#main-ul {
        @media (max-width: $break-max) {
            transition: all .2s;
            position: fixed;
            left: 100%;
            background: #545b4d;
            margin: 0;
            width: 80%;
            height: 100%;
            padding: 20px 0 0 0;
            overflow-y: scroll;
        }
        
        margin: 20px;
        list-style-type: none;
        float: right;

        & > li.main-li {
            @media (max-width: $break-max) {
                border-bottom: 1px solid #707964;
            }

            @media (min-width: $break-min) {
                float: left;
            }

            a {
                color: white;
                display: inline-block;
                padding: 8px;
                text-decoration: none;
                text-shadow: 0 0 5px #000;
                text-transform: uppercase;
                font-size: 15px;
                letter-spacing: .15em;

                @media (max-width: $break-max) {
                    padding: 25px;
                    display: block;
                }
            }

            & > ul {
                list-style-type: none;
                display: block;
                padding: 0 0 0 20px;
                margin: 0;
                float: none;
                @media (min-width: $break-min) {
                    display: none;
                    position: absolute;
                    padding: 0;
                }

                & > li {
                    float: none;
                }
            }

            @media (min-width: $break-min) {
                &:hover {
                    background: black !important;
                }

                &:hover > ul {
                    display: block;
                    background: black !important;
                }
            }
        }
    }

    transition: background .75s;
    &.sticky {
        background: black;
        height: 77px;

        //&.h {
        //    border-bottom: 5px solid #bfbfbf;
        //}
    }
}

#container {
    position: relative;
    left:0;
    transition: all .2s;

    &.menu-is-active {
        left: -80%;

        nav#main {
            ul {
                left: 20%;
            }
        }
    }
}

#audio-list {
    overflow: hidden;
    background: #eaeaea;
    padding: 30px 5px 30px 30px;
    //border-top: 1px solid #cecece;
    border-bottom: 1px solid #cecece;
}

#audible-list {
    overflow: hidden;
    &, * {
        font-size: 0;
    }

    a {
        width: calc(20% - 1px);

        @media (max-width: 500px) {
            width: 100%;
            margin-bottom: 15px;
        }
        @media (min-width: 501px) {
            margin-top: 20px;
        }

        line-height: 1px;

        display: inline-block;
        // float: left;
    }

    img {
        width: calc(100% - 20px);
        border: 1px solid #a0a0a0;
    }
}

#video-list {
    text-align: left;
    padding: 0;
    overflow: hidden;
    margin-top: 15px;

    width: calc(100% + 15px);
    @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 10px;
    }

    & > div {
        overflow:hidden;

        & > div {
            width: 33%;
            @media (max-width: 500px) {
                width: 100%;
            }
            float: left;
        }
    }

    iframe {
        width: calc(100% - 20px);
        height: 325px;
        //margin: 10px auto;
        //border: 1px solid #000;
        //box-shadow: 0 5px 5px #00000040;
        background: black;
    }
}

#videos-list {
    text-align: left;
    padding: 30px 5px 30px 30px;

    iframe {
        margin: 10px auto;
        border: 1px solid #9c9c9c;
        box-shadow: 0 5px 5px #00000040;

        max-width: calc(100% - 10px);
    }
}

#contact-form {
    text-align: center;
    padding: 30px;
    background: #eaeaea;
}

footer {
    height: 150px;
    background: #666;
    padding: 30px;
    text-align: center;
    color: white;
    font-size: 12px;

    a {
        color: #fff;
        font-size: 45px;
        display: inline-block;
        margin: 0 5px;
    }
}

/* .hamburger */
#menu-toggle {
    @media (min-width: $break-min) {
        display: none !important;
    }

    transition: all .2s !important;
    padding: 25px 25px;
    position: fixed;
    right: 0;
    outline: none;

    .menu-is-active & {
        right: 80%;
    }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
    background-color: #fff !important;
}

.h2 {
    font-size: 1.5em;
}

h2.sub,
h4 {
    margin-top: 60px;

    &:after {
        content: ":";
    }
}

h2.sub:first-child,
h4:first-child {
    margin-top: 20px;
}

h5 {
    clear: both;
    padding: 40px 0 5px 0;
}

h5:first-child {
    padding-top: 20px;
}


form {
    max-width: 800px;
    margin: 0 auto;
}

a.anchor {
    position: relative;
    // top: -77px; // ios safari doesn't handle it..
}

.col-50 {
    float: left;
    width: 100%;
    padding: 15px;

    @media (min-width: 768px) {
        width: calc(50% - 30px);
    }
}

#gallery {
    overflow: hidden;

    a {
        display: block;
        float: left;
        margin: 3px;

        img {
            object-fit: cover;
            width: 150px;
            height: 150px;
        }
    }
}

.audio-plyr {
    border:1px solid #dedede;
    background: #f3f3f3;
    color: #666;
    margin-right: 20px;

    /* Setup
    ================================================== */

    .container { position:relative; margin:0 auto; max-width:800px; width:100%; }
    .column { width:inherit; }


    /* Typography / Links
    ================================================== */

    p { color:#888; display:block; font-size:.9rem; font-weight:400; margin:0 0 2px; }

    a,a:visited { color:#8cc3e6; outline:0; text-decoration:underline; }
    a:hover,a:focus { color:#bbdef5; }
    p a,p a:visited { line-height:inherit; }


    /* Misc.
    ================================================== */

    .add-bottom { margin-bottom:2rem !important; }
    .left { float:left; }
    .right { float:right; }
    .center { text-align:center; }
    .hidden { display:none; }

    .no-support {
        margin:2rem auto;
        text-align:center;
        width:90%;
    }


    /* Audio Player Styles
    ================================================== */

    audio {
        display:none;
    }

    .audiowrap,
    .plwrap {
        margin:0 auto;
    }

    .tracks {
        font-size:0;
        position:relative;
        text-align:center;
    }

    .nowPlay {
        display:block;
        font-size:0;
    }

    .nowPlay span {
        display:inline-block;
        font-size:1.05rem;
        vertical-align:top;
    }

    .nowPlay span.npAction {
        padding:21px;
        width:30%;
    }

    .nowPlay span.npTitle {
        padding:21px;
        text-align:right;
        width:70%;
    }

    .plList {
        padding-left: 0;

        li {
            cursor:pointer;
            display:block;
            margin:0;
            padding:21px 0;

            &:hover {
                background-color:rgba(0,0,0,.1);
            }
        }
    }

    .plItem {
        position:relative;
    }

    .plTitle {
        left:50px;
        overflow:hidden;
        position:absolute;
        right:65px;
        text-overflow:ellipsis;
        top:0;
        white-space:nowrap;
    }

    .plNum {
        padding-left:21px;
        width:25px;
    }

    .plLength {
        padding-left:21px;
        position:absolute;
        right:21px;
        top:0;
    }

    .plSel,
    .plSel:hover {
        background-color:rgba(0,0,0,.1);
        color:#888;
        cursor:default !important;
    }

    .tracks a {
        border-radius:3px;
        color:#888;
        cursor:pointer;
        display:inline-block;
        font-size: 18px;
        line-height:.175;
        margin:0 5px 30px;
        padding:10px 15px;
        text-decoration:none;
        transition:background .3s ease;
    }

    .tracks a:last-child {
        margin-left:0;
    }

    .tracks a:hover,
    .tracks a:active {
        background-color:rgba(0,0,0,.1);
        color:#888;
    }

    .tracks a::-moz-focus-inner {
        border:0;
        padding:0;
    }


    /* Plyr Overrides
    ================================================== */

    .plyr--audio .plyr__controls {
        background-color:transparent;
        border:none;
        color:#888;
        padding:20px 20px 20px 13px;
        width:100%;
    }

    a.plyr__controls__item.plyr__control:hover,
    .plyr--audio .plyr__controls button:hover,
    .plyr--audio .plyr__controls button.tab-focus:focus,
    .plyr__play-large {
        background-color:rgba(0,0,0,.1);
    }

    .plyr__progress--played,
    .plyr__volume--display {
        color:rgba(0,0,0,.1);
    }

    .plyr--audio .plyr__progress--buffer,
    .plyr--audio .plyr__volume--display {
        background:rgba(0,0,0,.1);
    }

    .plyr--audio .plyr__progress--buffer {
        color:rgba(0,0,0,.1);
    }


    /* Media Queries
    ================================================== */

    @media only screen and (max-width:600px) {
        #nowPlay span#npAction { display:none; }
        #nowPlay span#npTitle { display:block; text-align:center; width:100%; }
    }
}

figure {
    display: inline-block;

    video {
        max-width: 100%;
        height: auto;
    }

    figcaption {
        text-align: center;
    }
}

.news-box {
    &.border-bottom {
        border-bottom:1px solid #e0e0e0;
        padding-bottom: 20px;
    }

    h2 {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        font-size: 1.75em;
    }

    strong {
        color: #000;
    }

    .news-item-list {
        list-style-type: none;
        padding-left: 10px;

        li {
            padding: 5px 0;
        }
    }

    div.ul {
        padding: 5px 0;
        margin-bottom: 1rem;
        padding-left: 10px;
    }
}

// https://codepen.io/andrewwright/pen/Aigre
.testimonial-quote {
    font-size: 16px;
    position: relative;

    blockquote {
        /* Negate theme styles */
        border: 0;
        margin: 0;
        padding: 0;

        background: none;
        color: gray;
        font-family: 'Gafata', serif; // Georgia, serif;
        font-size: 1.5em;
        font-style: italic;
        line-height: 1.4 !important;
        position: relative;
        text-shadow: 0 1px white;
        z-index: 2;

        * {
            box-sizing: border-box;
        }

        p {
            color: #75808a;
            line-height: 1.4 !important;

            &:first-child:before {
                font-family: Georgia, serif;
                content: '\201C';
                color: #cecece;
                font-size: 7.5em;
                font-weight: 700;
                opacity: .3;
                position: absolute;
                top: -.4em;
                left: -.2em;
                text-shadow: none;
                z-index: -300;
            }
        }
    }

    img {
        border: 3px solid #cecece;
        // border-radius: 50%;
        display: block;
        width: 120px;
        height: 120px;
        position: absolute;
        top: -.2em;
        left: 0;
    }

    cite {
        color: gray;
        display: block;
        font-size: .8em;

        span {
            color: #5e5e5e;
            font-size: 1em;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 1px;
            text-transform: uppercase;
            text-shadow: 0 1px white;
        }
    }

    .quote-container {
        padding-left: 160px;
    }

    &.right {
        .quote-container {
            padding-left: 0;
            padding-right: 160px;
        }

        img {
            left: auto;
            right: 0;
        }

        cite {
            text-align: right;
        }
    }
}

#news-list {
    padding: 0;
    overflow: hidden;
    margin-top: 15px;
    width: 100%;

    @media (max-width: 500px) {
        width: 100%;
        margin-bottom: 10px;
    }

    & > div {
        overflow: hidden;

        display: grid;
        grid-template-columns: 33.333% 33.333% 33.333%;
        @media (max-width: 1000px) {
            grid-template-columns: 50% 50%;
        }
        @media (max-width: 500px) {
            grid-template-columns: 100%;
        }
        padding: 10px;

        & > div {
            @media (max-width: 500px) {
                width: 100%;
            }
            float: left;
            text-align: center;
            padding: 20px;
        }
    }
}

ul#main-ul li.seconadry-li {
    float:left;
}

#container.menu-is-active {
    nav#main ul#secondary {
        /* border: 1px solid red; */
        padding: 6px;
        position: static;
        float: none;
        clear: both;
        margin: 0 0 18px 4px;
        overflow: hidden;
        font-size: 36px;
    }

    ul#main-ul li.seconadry-li {
        float:none;
    }

    ul#main-ul li.seconadry-li,
    ul#main-ul li.main-li:first-child {
        border-bottom: 1px solid #707964;
    }

    nav#main ul#secondary a {
        padding: 10px;
    }
}

.slick-items {
    overflow: hidden;
    height: 100px;

    button {
        display: none !important;
    }

    img {
        max-width: 100%;
        margin: 0 auto;
    }
}

// slick fix:
// https://stackoverflow.com/questions/37455322/vertical-align-content-slickslider
.slick-track {
    display: flex;
}
.slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}